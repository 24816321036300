import HomeHeader from "./HomeHeader";
import tosIcon from './res/tos.svg'
import ppIcon from './res/pp.svg'
import coinIcon from './res/coin-stack.svg'
import inquiryIcon from './res/mail.svg'
import questionIcon from './res/help.svg'
import logoutIcon from './res/logout.svg'
import exitIcon from './res/user-delete.svg'
import {useQuery} from "react-query";
import {FirebaseClient} from "./FirebaseClient";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";

type ButtonProps = { icon: string, text: string, fn: () => void }

export default function MenuPage() {
    const {data: config, error} = useQuery('getConfig', FirebaseClient.getConfig);
    const navigate = useNavigate();

    if (error) {
        return <div>{`${error}`}</div>
    }

    if (!config) {
        return <div>loading</div>
    }

    const {ppLink, questionsLink, tosLink, supportEmail} = config;

    const buttons: ButtonProps[] = [
        {text: '利用規約', icon: tosIcon, fn: () => openLink(tosLink)},
        {text: 'プライバシーポリシー', icon: ppIcon, fn: () => openLink(ppLink)},
        {text: 'コイン履歴', icon: coinIcon, fn: () => navigate("/purchaseHistory")},
        {text: 'お問い合わせ', icon: inquiryIcon, fn: () => openLink(`mailto:${supportEmail}`)},
        {text: 'よくある質問', icon: questionIcon, fn: () => openLink(questionsLink)},
        {text: 'ログアウト', icon: logoutIcon, fn: signOut},
        {text: '退会', icon: exitIcon, fn: () => navigate('/exit')},
    ]

    return <div>
        <HomeHeader/>
        <div id='menu'>
            <ul>
                {buttons.map(p =>
                    <li key={p.icon} className='split'>
                        <Button icon={p.icon} text={p.text} fn={p.fn}/>
                    </li>
                )}
            </ul>
        </div>
    </div>
}

function Button(props: ButtonProps) {
    const {fn, icon, text} = props;
    return <div className='button' onClick={fn}>
        <img src={icon} alt={text}/>
        <p>{text}</p>
    </div>
}

function openLink(link: string) {
    window.open(link, "_blank")
}

async function signOut() {
    ReactGA.event({
        category: 'Mypage',
        action: 'Logout',
        label: 'tap',
    })

    await FirebaseClient.signOut()
    window.location.assign('/')
}
