import HomeHeader from "./HomeHeader";
import {Fragment} from "react";
import {FirebaseClient, VoiceCache} from "./FirebaseClient";
import {useQuery} from "react-query";
import {AudioClient} from "./AudioClient";

export default function AdminPage() {
    return <div>
        <HomeHeader/>
        <Main/>
    </div>
}

function Main() {
    return <div id='admin-page'>
        <VoiceIndex/>
    </div>
}

function VoiceIndex() {
    const {data: voices} = useQuery("getAllCachedVoices", FirebaseClient.getAllCachedVoices)
    if (!voices) return <Fragment/>

    return <div className='voice-index'>
        <h2>Cached Voice Index</h2>
        {voices.map((v, i) =>
            <VoiceIndexElement voice={v} key={i}/>)
        }
    </div>
}

function VoiceIndexElement(props: { voice: VoiceCache }) {
    const {voice} = props;
    const {binary, key, text} = voice;
    const length = (binary?.byteLength / 1000).toFixed(1)

    return <div className='voice-index-element'>
        <div className='text font-small'>{text}</div>
        <textarea className='key' defaultValue={key}/>
        <div className='length font-small'>{length}kb</div>
        <button className='play' onClick={onClick}>Play</button>
    </div>

    async function onClick() {
        AudioClient.initAudioContext()
        await AudioClient.play(binary)
    }
}