import SignInHeader from "./SignInHeader";
import check from './res/check-big.svg'

export default function ExitDonePage() {
    return <div>
        <SignInHeader/>
        <div id='exit-done'>
            <img src={check} alt=''/>
            <h2>退会が完了しました。<br/>ご利用ありがとうございました。</h2>
            <button onClick={() => window.location.assign('/')} className='large-button blue'>ログイン画面へ戻る</button>
        </div>
    </div>
}