import {useQuery} from "react-query";
import {Character, FirebaseClient, Transaction, TransactionType} from "./FirebaseClient";
import HomeHeader from "./HomeHeader";
import coinIcon from './res/coin.svg'
import moment from "moment";
import {useBehaviorSubject} from "./Utils";

export default function PurchaseHistoryPage() {
    return <div>
        <HomeHeader/>
        <div id='purchase-history'>
            <h2>コイン履歴</h2>
            <Profile/>
        </div>
        <Feed/>
    </div>
}

function Profile() {
    const user = useBehaviorSubject(FirebaseClient.users, [])
    const coins = user?.coins + user?.bonusCoins

    return <div>
        <span>もっているコイン</span>
        <span className='font-bold'>{coins ?? '--'}枚</span>
    </div>
}

function Feed() {
    const {data: transactions, status, error} = useQuery("getTransactions", FirebaseClient.getTransactions)
    const {data: characters} = useQuery('getCharacters', FirebaseClient.getCharacters)
    const charactersMap = Object.fromEntries(characters?.map(c => [c.id, c]) ?? []) ?? {}

    if (error) {
        return <div>{`${error}`}</div>
    }

    if (status === "loading") {
        return <div>loading...</div>
    }

    if (transactions.length == 0) {
        return <div>empty</div>
    }

    return <div id='purchase-history-feed'>
        <ul>
            {transactions.map(t =>
                <li key={t.id} className='split'>
                    <PurchaseHistoryItem transaction={t} character={charactersMap[t.characterId]}/>
                </li>
            )}
        </ul>
    </div>
}

function PurchaseHistoryItem(props: { transaction: Transaction, character: Character }) {
    const {transaction, character} = props;
    const {type, coins, timestamp} = transaction;
    const consumed = type === 'consume'
    const date = moment(timestamp.toDate())
    const info = transactionInfo(type, character?.name)

    return <div className='main'>
        <div>
            <img src={coinIcon} alt='coin'/>
            <div>
                <p className='font-bold' style={{color: consumed ? 'red' : 'inherit'}}>{consumed ? '' : '+'}{coins} Coins</p>
                <p className='font-mid'>{info}</p>
            </div>
        </div>
        <p className='font-light font-small'>{date.format('YYYY/MM/DD HH:mm')}</p>
    </div>
}

function transactionInfo(type: TransactionType, characterName: string | undefined): string {
    switch (type) {
        case "purchase":
            return "コインを購入";
        case "bonus":
            return "ボーナスコイン配布";
        case "consume":
            return `${characterName}と会話`;
    }
}