import {v4 as uuidv4} from 'uuid'
import {AudioQueue} from "./AudioQueue";

type Key = [number, number]

export type ChatResponse = {
    text: string,
    blob?: Blob,
}

export class ChatEntry { // request & response
    id: string
    request: string
    responses: ChatResponse[]
    isResult: boolean
    private queuedAudioIndex: number
    private audioResponses: Set<number>

    constructor(request: string, response?: string) {
        this.id = uuidv4()
        this.request = request
        this.responses = []
        this.queuedAudioIndex = -1
        this.audioResponses = new Set<number>()

        if (response !== undefined) {
            this.isResult = true
            this.responses.push({text: response})
        }
    }

    onTextResponse(text: string) {
        this.responses.push({text: text})
    }

    onAudioResponse(key: Key, blob: Blob) {
        const [id, index] = key
        const response = this.responses[index]
        response.blob = blob
        this.audioResponses.add(index)

        let queueableAudioIndex = -1
        for (let i = 0; i < this.responses.length; i++) {
            if (!this.audioResponses.has(i)) {
                break
            }

            queueableAudioIndex = i
        }

        for (let i = this.queuedAudioIndex + 1; i <= queueableAudioIndex; i++) {
            const blob = this.responses[i].blob
            AudioQueue.queue(blob, [id, i])
        }

        this.queuedAudioIndex = queueableAudioIndex
    }
}