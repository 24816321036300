import React, {ReactElement} from "react";
import checkOn from "./res/check-on.svg";
import checkOff from "./res/check-off.svg";

export function SelectionButton(props: { onClick: () => void, isChecked: boolean, children: ReactElement | ReactElement[] }) {
    const {onClick, isChecked, children} = props;
    return <button onClick={onClick} className='selection-button'>
        <img src={isChecked ? checkOn : checkOff} alt='check'/>
        <div>
            {children}
        </div>
    </button>
}