import {Stripe} from "stripe";
import React, {useState} from "react";
import {useQuery} from "react-query";
import {FirebaseClient} from "./FirebaseClient";
import {StripeClient} from "./StripeClient";
import HomeHeader from "./HomeHeader";
import coin from './res/coin.svg'
import {SelectionButton} from "./ReactUtils";
import {ErrorClient} from "./ErrorClient";
import ReactGA from "react-ga4";

export default function PurchasePage() {
    return <div>
        <HomeHeader/>
        <div id='purchase'>
            <h2>コインを購入</h2>
            <Main/>
        </div>
    </div>
}

function Main() {
    const {data: products, status: productsStatus, error: productsError} = useQuery("products", FirebaseClient.getProducts);
    const {data: prices, status: pricesStatus, error: pricesError} = useQuery("prices", FirebaseClient.getPrices);
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const coinsPerRequest = config?.coinsPerRequest ?? 100
    const [checkingOut, setCheckingOut] = useState(false)
    const [productId, setProductId] = useState<string | null>(null)

    if (productsError || pricesError) {
        return <div>{`${productsError || pricesError}`}</div>
    }

    if (productsStatus === 'loading' || pricesStatus === 'loading') {
        return <div>loading</div>
    }

    const productMap = Object.fromEntries(products.map(p => [p.id, p]))
    const priceMap = Object.fromEntries(prices.map(p => [p.id, p]))

    return <div>
        <div className='selection-buttons'>
            {products.filter(predicateProduct).sort(compareProduct).map(p =>
                <ProductItem product={p} price={priceMap[p.default_price as string]} key={p.id}/>
            )}
        </div>
        <div className='buttons'>
            <SctaButton/>
            <button className='large-button blue' onClick={onPurchaseButtonPressed} disabled={!productId || checkingOut}>利用規約に同意して購入</button>
            {checkingOut && <span>Checking out...</span>}
        </div>
    </div>

    function ProductItem(props: { product: Stripe.Product, price: Stripe.Price }) {
        const {product, price} = props;
        const {id, name, metadata} = product
        const coins = parseInt(metadata['coins'])
        const requestCount = Math.floor(coins / coinsPerRequest)
        const {unit_amount} = price
        const checked = id === productId

        return <SelectionButton onClick={() => setProductId(id)} isChecked={checked}>
            <div className='purchase-item'>
                <div>
                    <img src={coin} alt='coin'/>
                    <div>
                        <p className='font-bold'>{name}</p>
                        <p>かいわ{requestCount}回ぶん</p>
                    </div>
                </div>
                <p className='font-bold'>¥{unit_amount}</p>
            </div>
        </SelectionButton>
    }

    function predicateProduct(p: Stripe.Product) {
        return p.active
    }

    function compareProduct(p0: Stripe.Product, p1: Stripe.Product) {
        return priceMap[p1.default_price as string].unit_amount - priceMap[p0.default_price as string].unit_amount
    }

    async function onPurchaseButtonPressed() {
        try {
            setCheckingOut(true)

            const product = productMap[productId]
            const priceId = priceMap[product.default_price as string].id
            await StripeClient.checkout(product.id, priceId)

            ReactGA.event({
                category: 'Mypage',
                action: 'CoinPurchaseCoinSelection',
                label: 'tap',
            })
        } catch (e) {
            console.error(e)
            ErrorClient.show({
                title: 'コイン購入に失敗しました',
                body: `${e}`,
            })
        } finally {
            setCheckingOut(false)
        }
    }

    function SctaButton() {
        const tos = config?.tosLink
        const scta = config?.sctaLink
        console.log(`${tos} ${scta}`)

        return <div className='policy-button'>
            <a href={tos} target='_blank'>利用規約</a>、
            <a href={scta} target='_blank'>特定商取引法について</a>
        </div>
    }
}