import * as rx from 'rxjs'

export type Error = {
    title: string,
    body: string,
    color?: string,
    ok?: string,
}

export namespace ErrorClient {
    export const errors = new rx.BehaviorSubject<Error | undefined>(undefined)

    export function show(error: Error) {
        errors.next(error)
    }

    export function hide() {
        errors.next(undefined)
    }
}

