import HomeHeader from "./HomeHeader";
import {FirebaseClient, UserProfile} from "./FirebaseClient";
import coin from './res/coin.svg'
import {Link} from "react-router-dom";
import male0 from './res/profiles/male0.svg'
import male1 from './res/profiles/male1.svg'
import male2 from './res/profiles/male2.svg'
import female0 from './res/profiles/female0.svg'
import female1 from './res/profiles/female1.svg'
import female2 from './res/profiles/female2.svg'
import pencil from './res/pencil.svg'
import {calculateAge, simpleHash, useBehaviorSubject} from "./Utils";
import ReactGA from "react-ga4";

const maleProfiles = [male0, male1, male2]
const femaleProfiles = [female0, female1, female2]

export default function ProfilePage() {
    return <div>
        <HomeHeader/>
        <Main/>
    </div>
}

function Main() {
    const user = useBehaviorSubject(FirebaseClient.users, [])

    if (!user) {
        return <div>loading</div>
    }

    const {bonusCoins, coins, gender, name, birth} = user;
    const totalCoins = coins + bonusCoins
    const age = calculateAge(birth)
    const genderStr = gender === 'male' ? 'おとこのこ' : 'おんなのこ'
    const pic = getPic(user)

    return <div id='profile'>
        <img src={pic} alt='profile' className='pic'/>
        <h2>{name}</h2>
        <p>{age}さい・{genderStr}</p>
        <Link to='/profile-edit' className='edit-name'>
            <img src={pencil} alt='edit'/>
            <p>なまえをへんこう</p>
        </Link>
        <div className='split'/>
        <div className='coins'>
            <img src={coin} alt='coin'/>
            <h2>{totalCoins}</h2><p>枚</p>
        </div>
        <p>購入{coins} ボーナス{bonusCoins}</p>
        <Link to='/purchase' className='purchase' onClick={onPurchaseButtonClicked}>
            コインを購入
        </Link>
    </div>

    function onPurchaseButtonClicked() {
        ReactGA.event({
            category: 'Mypage',
            action: 'CoinPurchaseMypage',
            label: 'tap',
        })
    }
}

export function getPic(profile: UserProfile | undefined): string {
    if (!profile) return ""

    //console.log(profile)

    const {id, gender} = profile
    const hash = Math.abs(simpleHash(id))
    if (gender === 'male') {
        const i = hash % maleProfiles.length
        return maleProfiles[i]
    } else {
        const i = hash % femaleProfiles.length
        return femaleProfiles[i]
    }
}