const separators = ['、', '。', '？', '！', '♪', '♡']

export default class TextSplitter {
    private buffer: string
    
    onSplit = (split: string) => {
        console.log(`not implemented; ${split}`)
    }

    constructor() {
        this.buffer = ""
    }

    append(text: string) {
        this.buffer += text

        if (separators.includes(text)) {
            this.flush()
        }
    }

    flush() {
        const buffer = this.buffer
        this.buffer = ""

        if (buffer !== "") {
            this.onSplit(buffer)
        }
    }
}