import * as rx from 'rxjs'
import {CoestationVoice, FirebaseClient} from "./FirebaseClient";

type Key = [number, number]

export type CoestationResponse = {
    key: Key,
    blob: Blob,
}

const baseUrl: string = location.host === 'aiueo-ai.com'
    ? "https://chatgpt-pyksjysm2q-an.a.run.app/coestation"
    : "https://chatgpt-ezxwzqdpra-an.a.run.app/coestation"

export namespace CoestationClient {
    export const audios = new rx.Subject<CoestationResponse>()
    export const thinking = new rx.BehaviorSubject(false)
    let voiceParams: CoestationVoice

    export function init() {
        voiceParams = {
            coe_id: 'p018195E6',
        }

        thinking.next(false)
    }

    export function configure(config: CoestationVoice) {
        voiceParams = config
        console.log(voiceParams)
    }

    export async function request(key: Key, text: string) {
        console.log(`>> coestation [${key}] '${text}'`)

        try {
            thinking.next(true)

            const params = JSON.stringify({
                ...voiceParams,
                'plain_text': text,
            })

            const [idToken, cachedVoice] = await Promise.all([
                FirebaseClient.getIdToken(),
                FirebaseClient.getCachedVoice(params),
            ])

            if (!idToken) throw new Error("no token")

            let voice: Blob
            if (cachedVoice) {
                voice = new Blob([cachedVoice])
                console.log('retrieved cache')
            } else {
                const res = await fetch(`${baseUrl}?token=${idToken}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: params,
                });

                voice = await res.blob()

                // cache
                const ab = await voice.arrayBuffer()
                const array = new Uint8Array(ab)
                await FirebaseClient.cacheVoice(params, text, array)
            }

            console.log(`<< coestation [${key}] (${Math.round(voice.size / 1000)}kb) '${text}'`)

            audios.next({
                key: key,
                blob: voice,
            })
        } finally {
            thinking.next(false)
        }
    }
}