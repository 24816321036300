import * as rx from 'rxjs'
import {ChatGptClient, ChatGptResponse} from "./ChatGptClient";
import {CoestationResponse, CoestationClient} from "./CoestationClient";
import {ChatEntry} from "./ChatEntry";
import {AudioQueue} from "./AudioQueue";
import {Character, FirebaseClient} from "./FirebaseClient";
import {Counter} from "./Counter";
import {LAppDelegate} from "./live2d-sample/lappdelegate";
import {SpeechClient} from "./SpeechClient";
import {LAppWavFileHandler} from "./live2d-sample/lappwavfilehandler";
import {AudioClient} from "./AudioClient";
import {ErrorClient} from "./ErrorClient";

export namespace ChatClient {
    export const character = new rx.BehaviorSubject<Character>(null)
    export const thinking = new rx.BehaviorSubject<boolean>(false)
    export const purchasePopup = new rx.BehaviorSubject(false)
    export const tutorial = new rx.BehaviorSubject(false)
    export const onEntryAdded = new rx.Subject<number>()
    export const entries = [] as ChatEntry[]
    let nextEntryIndex: number
    let ctor = false

    if (!ctor) {
        ctor = true

        ChatGptClient.sentences.subscribe(onChatGptResponse)
        CoestationClient.audios.subscribe(onCoestationResponse)
        SpeechClient.sent.subscribe(onSpeechSent)
        AudioQueue.onPlayingBlob.subscribe(onAudioPlayingBlob)

        rx.combineLatest([
            ChatGptClient.thinking,
            CoestationClient.thinking,
            AudioQueue.thinking,
        ]).subscribe(all => {
            const t = all.some(v => v)
            thinking.next(t)
        })
    }

    export async function init(characterId: string) {
        console.log('init')

        nextEntryIndex = 0
        entries.length = 0
        ChatGptClient.init()
        CoestationClient.init()
        AudioQueue.init()
        Counter.init(100)
        thinking.next(false)
        purchasePopup.next(false)

        const [c, u] = await Promise.all([
            FirebaseClient.getCharacter(characterId),
            rx.firstValueFrom(FirebaseClient.users.pipe(rx.filter(v => v !== null), rx.map(v => v!))),
        ]);

        character.next(c)
        tutorial.next(!!u.tutorial)

        window.document.removeEventListener('click', onAnyClick)
        window.document.addEventListener('click', onAnyClick)

        ChatGptClient.configure({character: c, user: u})
        CoestationClient.configure(c.voice)

        LAppDelegate.getInstance().loadModel(c.live2d)
        LAppDelegate.getInstance().bgColor = c.bg

        const pastMessages = await FirebaseClient.getChat(characterId);
        let pastMessageCount = 0

        for (const {userRawContent, assistantContent} of pastMessages) {
            if (!userRawContent || !assistantContent) continue

            entries.push(new ChatEntry(userRawContent, assistantContent))
            pastMessageCount += 1
        }

        onEntryAdded.next(pastMessageCount - 1)
        nextEntryIndex = pastMessageCount
    }

    export async function request(prompt: string) {
        console.log(`request: ${prompt}`)

        AudioClient.initAudioContext()

        const index = nextEntryIndex
        nextEntryIndex += 1

        entries.push(new ChatEntry(prompt))
        onEntryAdded.next(index)

        try {
            thinking.next(true)

            if (!await FirebaseClient.tryPayForRequest(character.value.id)) {
                purchasePopup.next(true)
                return
            }
        } catch (e) {
            console.error(e)
            ErrorClient.show({
                title: 'コイン消費に失敗しました',
                body: `${e}`,
            })
            return
        } finally {
            thinking.next(false)
        }

        try {
            await ChatGptClient.request(index, prompt)
        } catch (e) {
            console.error(e)
            ErrorClient.show({
                title: 'チャットの送信に失敗しました',
                body: `${e}`,
            })
        }
    }

    function onChatGptResponse(res: ChatGptResponse) {
        const {key: entryId, text} = res
        const entry = entries[entryId]
        const index = entry.responses.length

        entry.onTextResponse(text)
        CoestationClient.request([entryId, index], text).catch(console.error)
    }

    function onCoestationResponse(res: CoestationResponse) {
        const {blob, key} = res
        const [entryId, index] = key
        const entry = entries[entryId]
        entry.onAudioResponse([entryId, index], blob)
    }

    function onAudioPlayingBlob(blob: ArrayBuffer) {
        if (blob.byteLength == 0) {
            console.warn('empty audio')
            return
        }

        LAppWavFileHandler
            .getInstance()
            .start(Promise.resolve(blob))
            .catch(console.error)
    }

    export function closePurchasePopup() {
        purchasePopup.next(false)
    }

    function onAnyClick() {
        //console.log('on click')
        if (!tutorial.value) {
            tutorial.next(true)
            FirebaseClient.updateUserProfile({tutorial: true}).catch(console.error)
        }
    }

    async function onSpeechSent() {
        const prompt = SpeechClient.result.value.join(' ')
        if (prompt.length <= 0) return;

        console.log(prompt)
        await request(prompt)
    }
}