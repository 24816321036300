import logotype from "./res/logotype.svg";
import React from "react";
import left from './res/left.svg'
import {FirebaseClient} from "./FirebaseClient";

export default function SignInHeader() {
    return <div id='sign-in-header' className='split'>
        {location.pathname !== '/' &&
            <button onClick={exit}>
                <img src={left} alt='back'/>
            </button>
        }
        <img src={logotype} alt='logotype' className='logo'/>
    </div>

    async function exit() {
        await FirebaseClient.signOut()
        window.location.assign('/')
    }
}