import * as rx from 'rxjs'
import {ErrorClient} from "./ErrorClient";

export namespace SpeechClient {
    export const speaking = new rx.BehaviorSubject(false)
    export const sent = new rx.Subject()
    export const result = new rx.BehaviorSubject<string[]>([])
    let supported = false
    let sr: SpeechRecognition

    console.log('speech ctor')

    const ctor = window.webkitSpeechRecognition || window.SpeechRecognition

    if (ctor) {
        supported = true
        sr = new ctor()
        sr.lang = 'ja-JP'
        sr.continuous = true
        sr.interimResults = true
        sr.onerror = onerror
        sr.onresult = onresult
        //sr.onspeechstart = onspeechstart // doesn't work on ios
        console.log('speech initialized')
    } else {
        console.warn('speech not supported')
    }

    export function isSupported(): boolean {
        return supported
    }

    function onerror(e: SpeechRecognitionErrorEvent) {
        if (!e.message) return // manual abort

        console.error(e)
        ErrorClient.show({title: '音声入力に失敗しました', body: e.message})
        speaking.next(false)
    }

    function onresult(e: SpeechRecognitionEvent) {
        console.log(e)

        const buffer = [] as string[]
        for (let i = 0; i < e.results.length; i++) {
            const r = e.results.item(i)
            if (r.length > 0) {
                const a = r.item(0)
                buffer.push(a.transcript)
            }
        }

        if (buffer.length == 0) return;

        result.next([...buffer])
    }

    export function start() {
        console.log('speech start')

        sr.start()
        speaking.next(true)
        result.next([])
        //startAnimation().catch(console.error)
    }

    export function send() {
        console.log('speech send')

        sr.stop()
        speaking.next(false)
        sent.next({})
    }

    export function cancel() {
        console.log('speech cancel')

        sr.abort()
        speaking.next(false)
    }
}