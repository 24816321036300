import React, {FormEvent, useState} from "react";
import SignInHeader from "./SignInHeader";
import {FirebaseClient, UserProfile} from "./FirebaseClient";
import checkOn from './res/check-on.svg'
import checkOff from './res/check-off.svg'
import {useQuery} from "react-query";
import Select from 'react-select'
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);

export default function FirstSignInPage() {
    const [consent, setConsent] = useState(false);
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const tosLink = config?.tosLink
    const ppLink = config?.ppLink
    const [name, setName] = useState('');
    const [birth, setBirth] = useState<Date>(null);
    const [intro, setIntro] = useState('');
    const [gender, setGender] = useState('');
    const [referral, setReferral] = useState('');
    const canSubmit = consent && name && birth && intro && gender && referral

    return <div>
        <SignInHeader/>
        <div id='first-sign-in' className='vertical-form'>
            <h2>あなたのことをおしえてね</h2>
            <form onSubmit={handleInput}>
                <div className='item split'>
                    <p className='font-bold'>おなまえ</p>
                    <input type="text"
                           placeholder="なまえをにゅうりょく"
                           onChange={v => setName(v.target.value)}/>
                </div>
                <div className='item split'>
                    <p className='font-bold'>おたんじょうび</p>
                    <DatePicker
                        showYearDropdown={true}
                        scrollableYearDropdown={true}
                        yearDropdownItemNumber={50}
                        selected={birth}
                        onChange={setBirth}
                        maxDate={new Date()}
                        placeholderText='えらんでね'
                        locale='ja'/>
                </div>
                <div className='item split'>
                    <p className='font-bold'>じこしょうかい</p>
                    <input type="text"
                           placeholder="あなたのことをじゆうにかいてね"
                           onChange={v => setIntro(v.target.value)}/>
                </div>
                <div className='item split'>
                    <p className='font-bold'>せいべつ</p>
                    <Select className='select' classNamePrefix='select' onChange={v => setGender(v.value)} placeholder='えらんでね' options={[
                        {value: 'male', label: 'おとこのこ'},
                        {value: 'female', label: 'おんなのこ'},
                    ]}/>
                </div>
                <div className='item split'>
                    <p className='font-bold'>AIUEOをどうやって知りましたか？</p>
                    <Select className='select' classNamePrefix='select' onChange={v => setReferral(v.value)} placeholder='えらんでね' options={[
                        {value: 'youtube', label: "YouTube"},
                        {value: 'news', label: "ニュースアプリ"},
                        {value: 'ads', label: "広告バナー"},
                        {value: 'referral', label: "口コミ"},
                    ]}/>
                </div>
                <div className='consent font-small'>
                    <button type='button' onClick={() => setConsent(!consent)}>
                        <img src={consent ? checkOn : checkOff} alt='consent'/>
                    </button>
                    <p><a href={tosLink} target='_blank'>利用規約</a>と<a href={ppLink} target='_blank'>プライバシーポリシー</a>に同意する</p>
                </div>
                <button className='large-button blue' disabled={!canSubmit}>けってい</button>
            </form>
            <button className='sign-out font-bold font-blue' onClick={signOut}>別のアカウントで登録/ログイン</button>
        </div>
    </div>

    async function signOut() {
        await FirebaseClient.signOut()
        window.location.assign('/')
    }

    async function handleInput(event: FormEvent<HTMLFormElement>) {
        console.log('first sign in input submitting')
        event.preventDefault();

        const profile: Partial<UserProfile> = {
            'name': name,
            'birth': birth,
            'intro': intro,
            'gender': gender,
            'referral': referral,
        }

        await FirebaseClient.updateUserProfile(profile)

        window.location.assign('/')
    }
}
