import HomeHeader from "./HomeHeader";
import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FirebaseClient} from "./FirebaseClient";
import {useBehaviorSubject} from './Utils'

export default function ExitPage() {
    const user = useBehaviorSubject(FirebaseClient.users, [])
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        if (!user) return
        const {exitReason, exitDescription, exitSuggestion} = user ?? {}
        const intent = !!exitReason || !!exitDescription || !!exitSuggestion
        if (!intent) return
        setPageIndex(1)
    }, [user])

    return <div>
        <HomeHeader/>
        <div id='exit'>
            {getPage()}
        </div>
    </div>

    function getPage() {
        switch (pageIndex) {
            case 0:
                return <Page0/>
            case 1:
                return <Page1/>
            case 2:
                return <Page2/>
            default:
                return <Page0/>
        }
    }

    function Page0() {
        return <Fragment>
            <h2>退会する前に以下の内容をご確認ください。</h2>
            <p>退会時に以下の情報が削除されるため再度ご確認ください。</p>
            <ul className='font-bold default-list'>
                <li>ログイン情報</li>
                <li>保有コイン</li>
                <li>コイン使用履歴</li>
            </ul>
            <p>※お子様が誤ってアカウントを削除してしまった場合は別途お問い合わせまでご連絡をください。</p>
            <button onClick={() => setPageIndex(1)} className='large-button blue'>次へ</button>
            <Link to='/menu' className='large-button'>退会をキャンセル</Link>
        </Fragment>
    }

    function Page1() {
        const reasons = [
            '子供があまり楽しめなかった',
            '使う場面があまりない',
            '料金が高すぎる',
            '使い方がよくわからない',
            'その他',
        ]

        const textLimit = 300
        const [reason, setReason] = useState("");
        const [description, setDescription] = useState("");
        const [suggestion, setSuggestion] = useState("");
        const canSubmit = reason && description.length > 0 && description.length <= textLimit && (!suggestion || suggestion.length < textLimit)

        useEffect(() => {
            const {exitReason, exitDescription, exitSuggestion} = user
            setReason(exitReason ?? "")
            setDescription(exitDescription ?? "")
            setSuggestion(exitSuggestion ?? "")
        }, [user])

        return <Fragment>
            <h2>今後のサービス改善のため、アンケートに回答お願いいたします。</h2>
            <div className='item'>
                <p><span className='font-bold'>退会理由</span> <span className='font-light'>*必須</span></p>
                <select onChange={v => setReason(v.target.value)} value={reason}>
                    <option value="">退会理由を選んでください</option>
                    {reasons.map(o => <option key={o} value={o}>{o}</option>)}
                </select>
            </div>
            <div className='item'>
                <p><span className='font-bold'>具体的な退会理由</span> <span className='font-light'>*必須</span></p>
                <div className='textarea-item'>
                    <textarea rows={4} onChange={v => setDescription(v.target.value)} value={description} placeholder='都度コインを購入しなければならず、子どもにすぐ見せたいときに不便だった'/>
                    <p className={`font-small ${description.length > textLimit ? 'font-red' : 'font-light'}`}>{description.length} / {textLimit}</p>
                </div>
            </div>
            <div className='item'>
                <p><span className='font-bold'>AIUEOに改善してほしい箇所</span></p>
                <div className='textarea-item'>
                    <textarea rows={4} onChange={v => setSuggestion(v.target.value)} value={suggestion} placeholder='サブスクリプション形式の購入オプションを追加してほしい'/>
                    <p className={`font-small ${suggestion.length > textLimit ? 'font-red' : 'font-light'}`}>{suggestion.length} / {textLimit}</p>
                </div>
            </div>
            <button className='large-button red' onClick={() => submit()} disabled={!canSubmit}>退会する</button>
            <Link to='/menu' className='large-button'>退会をキャンセル</Link>
        </Fragment>

        async function submit() {
            try {
                await FirebaseClient.exit(reason, description, suggestion)
                window.location.assign('/exit-done')
            } catch (e) {
                setPageIndex(2)
            }
        }
    }

    function Page2() {
        return <Fragment>
            <h2>退会処理に失敗しました。</h2>
            <p>再ログインしてからお試しください。</p>
            <button onClick={onClick} className='large-button blue'>ログアウト</button>
        </Fragment>

        async function onClick() {
            await FirebaseClient.signOut()
            window.location.assign('/exit')
        }
    }
}