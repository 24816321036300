import {useBehaviorSubject} from "./Utils";
import {ErrorClient} from "./ErrorClient";
import React, {Fragment} from "react";

export function ErrorPopup() {
    const error = useBehaviorSubject(ErrorClient.errors, [])
    if (!error) return <Fragment/>

    const {body, color, ok, title} = error;

    return <div className='blocking-popup' onClick={onClick}>
        <div id='error'>
            <h2 className={color ? '' : 'font-red'} style={color ? {color: color} : {}}>{title}</h2>
            <p dangerouslySetInnerHTML={{__html: body}}/>
            <button className='large-button blue'>{ok ?? 'OK'}</button>
        </div>
    </div>

    function onClick() {
        ErrorClient.hide()
    }
}