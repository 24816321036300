import HomeHeader from "./HomeHeader";
import {Link} from "react-router-dom";

export default function PurchaseSuccessPage() {
    return <div>
        <HomeHeader/>
        <div id="purchase-result">
            <h2>コイン購入を受け付けました。</h2>
            <p>反映されるまでしばらくお待ちください。</p>
            <div>
                <Link to='/profile' className='large-button blue'>マイページへ</Link>
                <Link to='/purchase' className='large-button'>再び購入する</Link>
            </div>
        </div>
    </div>
}
