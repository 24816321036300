import {initializeApp} from 'firebase/app'
import * as fa from "firebase/auth"
import * as ff from 'firebase/firestore'
import * as fs from "firebase/storage";
import * as fn from 'firebase/functions'
import JSZip from "jszip"
import {Stripe} from "stripe";
import * as rx from 'rxjs'
import hexRgb, {RgbaObject} from 'hex-rgb';

const firebaseConfigDev = {
    "apiKey": "AIzaSyBIfEszonos6j1uiT9Kw0Xtay1r0dLfz1Q",
    "authDomain": "dev.aiueo-ai.com",
    "projectId": "aiueo-dev-527fe",
    "storageBucket": "aiueo-dev-527fe.appspot.com",
    "messagingSenderId": "870292099989",
    "appId": "1:870292099989:web:0cd0968a0ec698d3a61c9c"
}

const firebaseConfigProd = {
    "apiKey": "AIzaSyBYYZ6iAQIZ4Eqh-wNQTn8iYwiaJx-1J2A",
    "authDomain": "aiueo-ai.com",
    "projectId": "aiueo-prod",
    "storageBucket": "aiueo-prod.appspot.com",
    "messagingSenderId": "1089857561227",
    "appId": "1:1089857561227:web:205bfda8326ba027972a8e"
}

export const prod = location.host === 'aiueo-ai.com'
console.log(`prod: ${prod}`)
const firebaseConfig = prod ? firebaseConfigProd : firebaseConfigDev

export type UserProfile = {
    id: string,
    name: string,
    pic: string | undefined,
    gender: string, // male or female
    birth: Date,
    coins: number,
    bonusCoins: number,
    initBonusCoinsObtained: boolean,
    intro: string,
    referral: string,
    exitReason: string,
    exitDescription: string,
    exitSuggestion: string,
    tutorial: boolean,
    addedToHomeScreen: boolean,
}

export type TransactionType = 'purchase' | 'bonus' | 'consume'

export type Transaction = {
    id: string,
    type: TransactionType,
    coins: number,
    timestamp: ff.Timestamp,
    characterId?: string,
}

export type Character = {
    id: string,
    name: string,
    context: string,
    voice: CoestationVoice,
    live2d: string,
    bg: RgbaObject,
    description: string,
    icon: string,
    bye: string,
}

export type CoestationVoice = {
    coe_id: string,
}

export type Config = {
    coinsPerRequest: number,
    tosLink: string,
    ppLink: string,
    questionsLink: string,
    sctaLink: string,
    announcementLink: string,
    supportEmail: string,
    howToLink: string,
    promptTemplates: string[],
    maxTalkLength: number,
    initBonusCoins: number,
    initBonusCoinsEnabled: boolean,
    chatTemplate: string,
    wordLimit: number,
    chatSystem: string,
}

export type ChatGptMessagePair = {
    id: string,
    userContent?: string,
    userRawContent?: string,
    assistantContent?: string,
    complete: boolean,
}

export type VoiceCache = {
    key: string,
    text: string,
    binary: Uint8Array,
}

export type AuthState = 'loading' | 'signedOut' | 'signedIn' | 'firstSignIn'

export namespace FirebaseClient {
    const app = initializeApp(firebaseConfig)
    const auth = fa.getAuth(app)
    const firestore = ff.getFirestore(app)
    const storage = fs.getStorage(app);
    const functions = fn.getFunctions(app, "asia-northeast1")
    export const authentications = new rx.BehaviorSubject<AuthState>('loading')
    export const users = new rx.BehaviorSubject<UserProfile | null>(null)
    export const isDeveloper = new rx.BehaviorSubject<boolean>(false)
    let userDisposable: ff.Unsubscribe | null = null

    if (new URLSearchParams(window.location.search).get('localhost')) {
        console.log('localhost')
        //fa.connectAuthEmulator(auth, 'https://127.0.0.1:9099')
        //ff.connectFirestoreEmulator(firestore, '127.0.0.1', 8080)
        fn.connectFunctionsEmulator(functions, "127.0.0.1", 5001)
    }

    auth.onAuthStateChanged(onAuthNext, onAuthError)
    console.log('firebase client loaded')

    async function onAuthNext() {
        console.log('auth next')
        //console.log(auth.currentUser?.email)

        if (auth.currentUser) {
            const uid = auth.currentUser.uid
            console.log(`signed in: ${uid}`)
            observeUserProfile()
        } else if (!!await fa.getRedirectResult(auth)) {
            console.log('signed in via redirect')
        } else if (fa.isSignInWithEmailLink(auth, window.location.href)) {
            //https://firebase.google.com/docs/auth/web/email-link-auth
            console.log('email link sign in...')

            let email = window.localStorage.getItem('emailForSignIn')
            window.localStorage.removeItem('emailForSignIn')

            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('登録したメールアドレスを再入力してください');
            }

            await fa.signInWithEmailLink(auth, email, window.location.href)

            console.log('email link sign in done')
        } else {
            console.log('no sign-in')
            authentications.next('signedOut')
            users.next(null)
            userDisposable?.call(null)
            return
        }

        // signed in
        authentications.next("signedIn")
        isDeveloper.next(auth.currentUser.email.endsWith('@mosh-bit.com'))
        ensureUserInitialized().catch(console.error)
    }

    function onAuthError(error: Error) {
        console.log('auth error')
        console.log(error)
        authentications.next("signedOut")
    }

    export async function getIdToken() {
        return await auth.currentUser.getIdToken()
    }

    export async function signInWithGoogle() {
        console.log('signing in with google')
        const provider = new fa.GoogleAuthProvider()
        await fa.setPersistence(auth, fa.browserLocalPersistence)
        await fa.signInWithRedirect(auth, provider)
    }

    // https://firebase.google.com/docs/auth/web/email-link-auth
    export async function signUpWithEmailLink(email: string) {
        console.log(`sign up with email link: ${email}`)

        let redirectUrl = new URL(window.location.href)
        redirectUrl.pathname = "/profile"

        await fa.sendSignInLinkToEmail(auth, email, {
            url: `${redirectUrl.href}`,
            handleCodeInApp: true,
        })

        window.localStorage.setItem('emailForSignIn', email)

        console.log('sign up with email link done')
    }

    async function ensureUserInitialized() {
        const docRef = ff.doc(firestore, `users/${auth.currentUser.uid}`)
        const doc = await ff.getDoc(docRef)
        const initialized = doc.exists() && doc.get('initialized')

        if (!initialized) {
            authentications.next('firstSignIn')
        }
    }

    export async function signOut() {
        await fa.signOut(auth)
        authentications.next("signedOut")
        users.next(null)
    }

    export async function exit(reason: string, description: string, suggestion: string) {
        const docRef = ff.doc(firestore, `users/${auth.currentUser.uid}`)
        await ff.setDoc(docRef, {
            'exit': true,
            'exitReason': reason,
            'exitDescription': description,
            'exitSuggestion': suggestion,
        }, {merge: true})

        await fa.deleteUser(auth.currentUser)
    }

    function observeUserProfile() {
        const docRef = ff.doc(firestore, `users/${auth.currentUser.uid}`)
        userDisposable?.call(null)
        userDisposable = ff.onSnapshot(docRef, {
            next: doc => {
                console.log('user updated')
                const u = doc.data() as UserProfile
                if (!u) return
                u.id = doc.id
                u.birth = (doc.get('birth') as ff.Timestamp)?.toDate()
                u.coins ??= 0
                u.bonusCoins ??= 0
                users.next(u)
            },
            error: console.error,
        })
    }

    export async function updateUserProfile(part: Partial<UserProfile>) {
        console.log(`updateUserProfile(${JSON.stringify(part)})`)
        const docRef = ff.doc(firestore, `users/${auth.currentUser.uid}`)
        await ff.setDoc(docRef, {
            ...part,
            'email': auth.currentUser.email,
            initialized: true,
        }, {merge: true})
    }

    export async function getConfig(): Promise<Config> {
        const docRef = ff.doc(firestore, 'configs/config')
        const doc = await ff.getDoc(docRef)
        return doc.data() as Config
    }

    export async function getCachedVoice(key: string) {
        const docRef = ff.doc(firestore, `voices/${key}`);
        const doc = await ff.getDoc(docRef)
        return doc?.get('binary')?.toUint8Array() as Uint8Array | undefined
    }

    export async function cacheVoice(key: string, text: string, voice: Uint8Array) {
        const docRef = ff.doc(firestore, `voices/${key}`);
        await ff.setDoc(docRef, {'text': text, 'binary': ff.Bytes.fromUint8Array(voice),}, {merge: true})
    }

    export async function getAllCachedVoices() {
        const list: VoiceCache[] = []
        const colRef = ff.collection(firestore, 'voices');
        const docs = await ff.getDocs(ff.query(colRef))
        for (const doc of docs.docs) {
            const cache = doc.data() as VoiceCache
            cache.key = doc.id
            cache.binary = doc.get('binary')?.toUint8Array()
            list.push(cache)
        }

        return list
    }

    export async function getTransactions(): Promise<Transaction[]> {
        const query = ff.query(
            ff.collection(firestore, 'transactions'),
            ff.where('uid', '==', auth.currentUser.uid))

        const docs = await ff.getDocs(query)
        return docs.docs.map(d => {
            const v = d.data() as Transaction
            v.id = d.id
            return v
        })
    }

    export async function distributeInitBonusCoins() {
        console.log('distributeInitBonusCoins')
        await fn.httpsCallable(functions, 'distributeInitBonusCoins')({})
    }

    export async function tryPayForRequest(characterId: string): Promise<boolean> {
        const config = await getConfig()
        const user = users.value!
        const coins = (user.coins + user.bonusCoins) ?? 0
        const coinsPerRequest = config.coinsPerRequest
        if (coins < coinsPerRequest) {
            return false
        }

        const r = await fn.httpsCallable(functions, 'tryPayForRequest')({
            'characterId': characterId,
        })
        return r.data as boolean
    }

    export async function getCharacters(): Promise<Character[]> {
        console.log('loading characters')
        const characters = [] as Character[]

        const col = ff.collection(firestore, 'characters');
        const docs = await ff.getDocs(col);
        docs.forEach(doc => {
            const c = makeCharacter(doc)
            characters.push(c)
        })

        console.log('done loading characters')
        return characters
    }

    export async function getCharacter(id: string): Promise<Character> {
        console.log(`loading character: ${id}`)
        const ref = ff.doc(firestore, `characters/${id}`);
        const doc = await ff.getDoc(ref);
        const character = makeCharacter(doc)
        console.log('done loading character')
        return character
    }

    function makeCharacter(doc: ff.DocumentSnapshot): Character {
        const c = doc.data() as Character
        c.id = doc.id
        c.voice = JSON.parse(doc.get('voice'))
        c.bg = hexRgb(doc.get('bg') ?? '#111111')
        return c
    }

    export async function setSystem(cid: string, system: string) {
        console.log(`setSystem(${cid}, ${system})`)
        const uid = auth.currentUser.uid
        const docRef = ff.doc(firestore, `chats/${uid}-${cid}`)
        await ff.setDoc(docRef, {'system': system}, {merge: true})
    }

    export async function appendChatMessage(cid: string, message: ChatGptMessagePair): Promise<void> {
        console.log(`appendChat(${cid}, ${JSON.stringify(message)})`)
        const {maxTalkLength} = await getConfig()

        await ff.runTransaction(firestore, async (transaction) => {
            const uid = auth.currentUser.uid
            const docRef = ff.doc(firestore, `chats/${uid}-${cid}`)
            const doc = await transaction.get(docRef)
            let messages = doc.get('messages') as ChatGptMessagePair[] ?? []

            const existingMessage= messages.find(m => m.id == message.id)
            if (existingMessage) {
                Object.assign(existingMessage, message)
            } else {
                messages.push(message)
            }

            messages = messages.reverse().slice(0, maxTalkLength).reverse()
            transaction.set(docRef, {'messages': messages}, {merge: true})
        })
    }

    export async function getChat(cid: string): Promise<ChatGptMessagePair[]> {
        const uid = auth.currentUser.uid
        const docRef = ff.doc(firestore, `chats/${uid}-${cid}`)
        const doc = await ff.getDoc(docRef)
        return doc.get('messages') as ChatGptMessagePair[] ?? []
    }

    export async function downloadLive2d(id: string): Promise<JSZip> {
        const ref = fs.ref(storage, `live2d/${id}.zip`);
        const bytes = await fs.getBytes(ref);
        const jsZip = new JSZip();
        await jsZip.loadAsync(bytes);
        return jsZip
    }

    export async function getDownloadUrl(path: string): Promise<string> {
        const ref = fs.ref(storage, path)
        return await fs.getDownloadURL(ref);
    }

    export async function getProducts(): Promise<Stripe.Product[]> {
        const r = await fn.httpsCallable(functions, 'products')()
        return r.data as Stripe.Product[]
    }

    export async function getPrices(): Promise<Stripe.Price[]> {
        const r = await fn.httpsCallable(functions, 'prices')({})
        return r.data as Stripe.Price[]
    }

    export async function checkout(product: string, price: string, successUrl: string, cancelUrl: string): Promise<string> {
        console.log(`checkout(${price}, ${successUrl}, ${cancelUrl})`)

        const r = await fn.httpsCallable(functions, 'checkout')({
            'product': product,
            'price': price,
            'success_url': successUrl,
            'cancel_url': cancelUrl,
        })

        return r.data as string
    }
}
