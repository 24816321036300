import React, {Fragment, useState} from 'react'
import {useQuery} from "react-query";
import {Character, FirebaseClient} from "./FirebaseClient";
import HomeHeader from "./HomeHeader";
import character_type from './res/charactor-type.svg'
import {useNavigate} from "react-router-dom";
import bonus from './res/bonus.png'
import {useBehaviorSubject} from './Utils'
import TouchHomePopup from './TouchHomePopup'
import ReactGA from 'react-ga4';

export default function HomePage() {
    return <div id='home'>
        <HomeHeader/>
        <CharacterList/>
        <BonusCoinsPopup/>
        <TouchHomePopup/>
    </div>
}

function CharacterList() {
    const {data: characters, error} = useQuery("getCharacters", FirebaseClient.getCharacters);

    if (error) {
        return <div>{`${error}`}</div>
    }

    if (!characters) {
        return <div>loading</div>
    }

    return <div className='main'>
        {characters.map(c =>
            <div key={c.id}>
                <CharacterListItem character={c}/>
            </div>
        )}
    </div>
}

function CharacterListItem(props: { character: Character }) {
    const {character: {id, name, description, icon, bg}} = props;
    const {alpha, blue, green, red} = bg;
    const bgColor = `rgb(${red}, ${green}, ${blue}, ${alpha})`
    const {data: iconSvg} = useQuery(`character icon ${id}`, () => FirebaseClient.getDownloadUrl(icon));
    const navigate = useNavigate();

    return <div onClick={onClick} className='character-list-item'>
        <div className='icon' style={{backgroundColor: bgColor}}>
            <img className='character' src={iconSvg} alt='icon'/>
            <img className='character-type' src={character_type} alt='type'/>
        </div>
        <div className='caption'>
            <h2>{name}</h2>
            <p>{description}</p>
        </div>
    </div>

    function onClick() {
        navigate(`/chat/${id}`)
        ReactGA.event({
            action: "StartChat",
            category: "Chat",
            label: "tap",
        })
    }
}

function BonusCoinsPopup() {
    const user = useBehaviorSubject(FirebaseClient.users, [])
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const [disabled, setDisabled] = useState(false);
    if (!user || !config) return <Fragment/>
    if (user.initBonusCoinsObtained) return <Fragment/>
    if (!config.initBonusCoinsEnabled) return <Fragment/>

    return <div className='blocking-popup'>
        <div id='bonus-coins-popup'>
            <h2>ボーナスコインを<br/>かくとく！</h2>
            <img src={bonus} alt=''/>
            <p className='font-bold font-large'><span className='font-xlarge'>{config.initBonusCoins}</span>枚</p>
            <button className='large-button blue' onClick={onClick} disabled={disabled}>{disabled ? 'うけとりちゅう...' : 'うけとる'}</button>
        </div>
    </div>

    async function onClick() {
        setDisabled(true)
        await FirebaseClient.distributeInitBonusCoins()
    }
}