import * as rx from 'rxjs'
import {AudioClient} from "./AudioClient";

type Key = [number, number]

export namespace AudioQueue {
    export const onPlaying = new rx.Subject<Key>()
    export const onPlayingBlob = new rx.Subject<ArrayBuffer>()
    export const thinking = new rx.BehaviorSubject(false)
    const list = [] as [Blob, Key][]
    let promise: Promise<any> | undefined

    export function init() {
        list.length = 0
        promise = undefined
    }

    export function queue(blob: Blob, id: Key) {
        list.push([blob, id])

        if (!promise) {
            promise = dequeue()
        }
    }

    async function dequeue() {
        if (list.length === 0) {
            promise = undefined
            return
        }

        try {
            thinking.next(true)

            const [blob, id] = list.shift()
            onPlaying.next(id)
            console.log(`playing audio [${id}]`)
            if (!blob) {
                console.warn(`blob null: [${id}]`)
            } else {
                const ab = await blob.arrayBuffer()
                onPlayingBlob.next(ab)
                await AudioClient.play(ab)
            }
        } catch (e) {
            console.error(e)
        } finally {
            thinking.next(false)
        }

        await dequeue()
    }

    export function observePlaying(index: number): rx.Observable<number> {
        return onPlaying.pipe(
            rx.filter(([p,]) => p == index),
            rx.map(([, p]) => p))
    }
}