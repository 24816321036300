import * as rx from 'rxjs'
import React, {DependencyList, useEffect, useLayoutEffect, useState} from "react";

export function formatMs(time: number) {
    return (time * 0.001).toFixed(2)
}

export async function sleep(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms))
}

export function useSubject<T>(state: rx.BehaviorSubject<T>, keys: (keyof T)[]): T {
    const s = state.pipe(
        rx.startWith(state.value),
        rx.pairwise(),
        rx.filter(v => isChanged(keys, v)),
        rx.map(v => v[1]))
    return useObservable(s, state.value, [])
}

export function isChanged<T>(keys: (keyof T)[], pair: T[]) {
    const [oldState, newState] = pair
    for (let key of keys) {
        const oldValue = oldState[key]
        const newValue = newState[key]
        if (oldValue !== newValue) {
            return true
        }
    }

    return false
}

export function useObservable<T>(
    observable: rx.Observable<T>,
    defaultState: T,
    deps?: React.DependencyList | undefined): T {

    const [state, setState] = useState(defaultState)

    useLayoutEffect(() => {
        const s = observable.subscribe(v => setState(v))
        return () => s.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps ?? [])

    return state
}

export function useBehaviorSubject<T>(subject: rx.BehaviorSubject<T>, deps?: React.DependencyList) {
    return useObservable(subject, subject.value, deps)
}

export function useEffectAsync(f: () => Promise<any>, deps: DependencyList) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        f().catch(console.error)
    }, deps)
}

export function useChange<T>(value: T, f: (previousValue: T) => void, deps?: DependencyList) {
    const [currentValue, setCurrentValue] = useState(value)
    useEffect(() => {
        if (currentValue !== value) {
            const previousValue = currentValue
            setCurrentValue(value)
            f(previousValue)
        }
    }, [value, ...(deps ?? [])])
}

export function simpleHash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        let charCode = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + charCode;
        hash |= 0; // Convert to 32-bit integer
    }
    return hash;
}

export function calculateAge(birthdate: Date): number {
    let today = new Date();
    let birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export function lerp(a: number, b: number, t: number): number {
    return a + (b - a) * t
}

export function inverseLerp(v: number, a: number, b: number): number {
    return (v - a) / (b - a);
}

export function getTouchOsVersion(userAgent: string): number | null {
    if (/iP(ad|hone|od).+Version\/([\d.]+)/i.test(userAgent)) {
        return parseFloat(userAgent.match(/Version\/([\d.]+)/)[1]);
    }

    return null
}
