import React, {Fragment, useEffect, useRef, useState} from 'react';
import {ChatClient} from "./ChatClient";
import {Link, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {FirebaseClient} from "./FirebaseClient";
import {ChatEntry} from "./ChatEntry";
import {Counter} from "./Counter";
import {AudioQueue} from "./AudioQueue";
import book from './res/book.svg'
import exit from './res/exit.svg'
import send from './res/send.svg'
import BlockingPopup from "./BlockingPopup";
import {getPic} from "./ProfilePage";
import HomeHeader from "./HomeHeader";
import mic from './res/mic.svg'
import typing from './res/typing.svg'
import {SpeechClient} from "./SpeechClient";
import left from './res/left.svg'
import {SpeechOverlay} from "./SpeechOverlay";
import {useObservable, useBehaviorSubject, getTouchOsVersion} from "./Utils";
import ReactGA from 'react-ga4';
import {ErrorClient} from "./ErrorClient";

export default function ChatPage() {
    const {id} = useParams<{ id: string }>();
    const [done, setDone] = useState(false);

    useEffect(() => {
        ChatClient.init(id).catch(console.error)
        showTouchVersionWarning()
    }, [id])

    if (done) {
        return <EndPage/>
    }

    return <div>
        <div id='chat'>
            <ChatFeed/>
            <Form/>
        </div>
        <Header/>
        <SpeechOverlay/>
        <PurchasePopup/>
    </div>

    function Header() {
        const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
        const howToLink = config?.howToLink

        return <div id='chat-header'>
            <a href={howToLink} target='_blank' onClick={onClickHowToPlay}>
                <img src={book} alt='how to play'/>
            </a>
            <button onClick={onExitButtonPressed}>
                <img src={exit} alt='exit'/>
            </button>
        </div>

        function onClickHowToPlay() {
            ReactGA.event({
                category: 'Chat',
                action: 'OpenGuide',
                label: 'tap',
            })
        }
    }

    function onExitButtonPressed() {
        setDone(true)

        ReactGA.event({
            category: 'Chat',
            action: 'ChatEnd',
            label: 'tap',
        })
    }
}

function showTouchVersionWarning() {
    console.log(navigator.userAgent)
    const touchVersion = getTouchOsVersion(navigator.userAgent)
    if (!touchVersion) return
    if (touchVersion >= 16) return

    ErrorClient.show({
        title: '推奨環境について',
        body: `<p class="font-bold" style="margin-bottom: 10px">aiueoの推奨環境は、iOS16以上となっております。</p><p>iOS15以下の場合、音声が再生されない等の不具合が発生する場合がございます。</p>`,
        color: '#FF7A00',
        ok: '確認しました'
    })
}

function Form() {
    const [prompt, setPrompt] = useState("")
    const thinking = useBehaviorSubject(ChatClient.thinking, []);
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const promptTemplates = config?.promptTemplates ?? []
    const input = useRef<HTMLInputElement | null>(null);
    const [keyboardActive, setKeyboardActive] = useState(false);

    const wordLimit = config?.wordLimit ?? 140
    const wordLimitNear = prompt.length > wordLimit - 30
    const wordLimitReached = prompt.length > wordLimit
    const wordLimitColor = wordLimitReached ? 'red' : wordLimitNear ? 'yellow' : 'inherit'

    useEffect(() => {
        if (keyboardActive) {
            console.log(input.current)
            input.current?.focus()
        }
    }, [keyboardActive])

    return <div id='chat-form'>
        {!keyboardActive &&
            <div className='main'>
                <div className='default-buttons'>
                    {promptTemplates.map(p =>
                        <button key={p} onClick={() => ChatClient.request(p)} disabled={thinking}>{p}</button>
                    )}
                </div>
                <div className='main-buttons'>
                    <button className='large-button blue' onClick={onKeyboardButtonTapped}>
                        <img src={typing} alt='typing'/>
                        <p>もじにゅうりょく</p>
                    </button>
                    <button className='large-button blue' onClick={onSpeechButtonTapped} disabled={!SpeechClient.isSupported()}>
                        <img src={mic} alt='mic'/>
                        <p>はなす</p>
                    </button>
                </div>
            </div>
        }
        {keyboardActive &&
            <div className='keyboard'>
                <button onClick={cancel}>
                    <img src={left} alt='cancel'/>
                </button>
                <div className='input-container'>
                    <input ref={input} value={prompt} placeholder='おはなしをしてみよう' onChange={change} onSubmit={submit}/>
                    {wordLimitNear && <p style={{color: wordLimitColor}}>{prompt.length}/{wordLimit}</p>}
                </div>
                <button onClick={submit} disabled={!prompt || thinking || wordLimitReached}>
                    <img src={send} alt='send'/>
                </button>
            </div>
        }
    </div>

    function onKeyboardButtonTapped() {
        setKeyboardActive(true)

        ReactGA.event({
            category: 'Chat',
            action: 'KeyboardInputMode',
            label: 'tap',
        })
    }

    function change() {
        setPrompt(input.current?.value)
    }

    async function submit() {
        ChatClient.request(prompt).catch(console.error)
        setPrompt("")
        setKeyboardActive(false)

        ReactGA.event({
            category: 'Chat',
            action: 'KeyboardInputSubmission',
            label: 'tap',
        })
    }

    async function cancel() {
        setPrompt("")
        setKeyboardActive(false)
    }

    function onSpeechButtonTapped() {
        SpeechClient.start()

        ReactGA.event({
            category: 'Chat',
            action: 'VoiceInputMode',
            label: 'tap',
        })
    }
}

function ChatFeed() {
    useObservable(ChatClient.onEntryAdded, -1, [])

    const noFeed = !!(new URLSearchParams(window.location.search).get('no-feed'));

    const i = ChatClient.entries.length - 1
    const e = ChatClient.entries[i]
    return <div id='chat-feed'>
        {e && !noFeed && <Fragment key={e.id}>
            <ChatResponseBubble entry={e} index={i}/>
            <ChatRequestBubble content={e.request}/>
        </Fragment>
        }
        <Tutorial/>
    </div>
}

function ChatRequestBubble(props: { content: string }) {
    const {content} = props
    const user = useBehaviorSubject(FirebaseClient.users, [])
    const pic = getPic(user)

    return <div className='request bubble'>
        <img src={pic} alt='user'/>
        <p>{content}</p>
    </div>
}

function ChatResponseBubble(props: { entry: ChatEntry, index: number }) {
    const {entry, index} = props
    const [counterOffset, setCounterOffset] = useState(Counter.counter.value)
    const rawCounter = useObservable(Counter.counter, 0)
    const counter = rawCounter - counterOffset
    const audioIndex = useObservable(AudioQueue.observePlaying(index), -1, [])
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [touched, setTouched] = useState(false);

    // reset auto scroll when new message begins
    useEffect(() => setTouched(false), [index])

    // reset the caret position when new audio starts playing
    useEffect(() => setCounterOffset(rawCounter), [audioIndex])

    // auto-scroll to the bottom
    useEffect(() => {
        if (touched) return

        const container = scrollRef.current
        if (!container) return

        container.scroll({
            top: container.scrollHeight - container.clientHeight,
            behavior: 'smooth'
        });
    }, [counter]);

    const sentences = entry.responses.map(s => s.text)

    let text: string
    if (entry.isResult) {
        text = sentences.join('')
    } else if (audioIndex < 0) {
        text = ""
    } else {
        //console.log(sentences)
        text = sentences.slice(0, audioIndex).join("")
        //console.log(`audio index: [${index},${audioIndex}]`)
        text += sentences[audioIndex].substring(0, counter)
    }

    if (!text) {
        text = '...'
    }

    return <div className='response bubble speaking-bubble'
                onPointerDown={() => setTouched(true)}>
        <div ref={scrollRef}>
            <p>{text}</p>
        </div>
    </div>
}

function PurchasePopup() {
    const purchasePopup = useBehaviorSubject(ChatClient.purchasePopup, []);
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const coinsPerRequest = config?.coinsPerRequest ?? 0

    const user = useBehaviorSubject(FirebaseClient.users, [])
    const coins = user?.coins + user?.bonusCoins ?? 0

    if (!purchasePopup) return <Fragment/>

    return <BlockingPopup>
        <div id='purchase-popup'>
            <h2>コインが足りません</h2>
            <p className='font-bold'>1回のかいわに<span className='font-orange'>{coinsPerRequest}枚ひつよう</span>です。</p>
            <p>もっているコイン{coins}枚</p>
            <Link to='/purchase' className='large-button blue' onClick={onPurchaseButtonClicked}>コインをかう</Link>
            <button className='large-button' onClick={() => ChatClient.closePurchasePopup()}>かわない</button>
        </div>
    </BlockingPopup>

    function onPurchaseButtonClicked() {
        ReactGA.event({
            category: 'Mypage',
            action: 'CoinPurchaseChat',
            label: 'tap',
        })
    }
}

function Tutorial() {
    const tutorial = useBehaviorSubject(ChatClient.tutorial, []);
    if (tutorial) return <Fragment/>

    return <div id='chat-tutorial'>
        <div className='tutorial-bubble speaking-bubble font-bold'>
            したのボタンをおしたり、<br/>もじをにゅうりょくして<br/>おはなしをしてみよう！
        </div>
    </div>
}

function EndPage() {
    const character = useBehaviorSubject(ChatClient.character, []);
    const {bye, icon, id, bg} = character
    const {data: iconSvg} = useQuery(`character icon ${id}`, () => FirebaseClient.getDownloadUrl(icon));
    const bgColor = `rgb(${bg.red}, ${bg.green}, ${bg.blue})`

    return <div id='chat-end-page'>
        <HomeHeader/>
        <div className='main'>
            <img src={iconSvg} alt='icon' style={{backgroundColor: bgColor}}/>
            <h2>{bye}</h2>
            <Link to='/' className='large-button blue'>ホームへ</Link>
        </div>
    </div>
}