import './index.css';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {FirebaseClient, prod} from "./FirebaseClient";
import HomePage from "./HomePage";
import SignInPage from "./SignInPage";
import ChatPage from "./ChatPage";
import LoadingPage from "./LoadingPage";
import {LAppDelegate} from "./live2d-sample/lappdelegate";
import PurchasePage from "./PurchasePage";
import PurchaseSuccessPage from "./PurchaseSuccessPage";
import PurchaseCancelPage from "./PurchaseCancelPage";
import FirstSignInPage from "./FirstSignInPage";
import PurchaseHistoryPage from "./PurchaseHistoryPage";
import ProfilePage from "./ProfilePage";
import MenuPage from "./MenuPage";
import ExitPage from "./ExitPage";
import ExitDonePage from "./ExitDonePage";
import {useBehaviorSubject} from "./Utils";
import ProfileEditPage from "./ProfileEditPage";
import ReactGA from 'react-ga4';
import AdminPage from "./AdminPage";
import {ErrorPopup} from "./ErrorPopup";

ReactGA.initialize(prod ? 'G-2QCWN0G9DC' : 'G-Y986J7F4NL')

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

LAppDelegate.getInstance().initialize()
LAppDelegate.getInstance().run();
LAppDelegate.getInstance().setVisible(false)

window.onresize = () => {
    LAppDelegate.getInstance().onResize();
}

window.onbeforeunload = () => {
    LAppDelegate.releaseInstance();
}

const queryClient = new QueryClient()

root.render(
    <QueryClientProvider client={queryClient}>
        <App/>
        <ErrorPopup/>
    </QueryClientProvider>
)

function App() {
    const authState = useBehaviorSubject(FirebaseClient.authentications, [])

    if (window.location.href.includes('/exit-done')) {
        return <ExitDonePage/>
    }

    if (authState === 'loading') {
        return <LoadingPage/>
    }

    if (authState === 'signedOut') {
        return <SignInPage/>
    }

    if (authState === 'firstSignIn') {
        return <FirstSignInPage/>
    }

    return (
        <BrowserRouter>
            <Main/>
        </BrowserRouter>
    )
}

function Main() {
    const location = useLocation()

    useEffect(() => {
        const isChatPage = location.pathname.includes('/chat/')
        LAppDelegate.getInstance().setVisible(isChatPage)
    }, [location])

    return <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/chat/:id' element={<ChatPage/>}/>
        <Route path='/purchase' element={<PurchasePage/>}/>
        <Route path='/purchase/success' element={<PurchaseSuccessPage/>}/>
        <Route path='/purchase/cancel' element={<PurchaseCancelPage/>}/>
        <Route path='/purchaseHistory' element={<PurchaseHistoryPage/>}/>
        <Route path='/profile' element={<ProfilePage/>}/>
        <Route path='/profile-edit' element={<ProfileEditPage/>}/>
        <Route path='/menu' element={<MenuPage/>}/>
        <Route path='/exit' element={<ExitPage/>}/>
        <Route path='/admin' element={<AdminPage/>}/>
    </Routes>
}

