import {SpeechClient} from "./SpeechClient";
import React, {Fragment, useEffect, useRef} from "react";
import speechExit from "./res/speech-exit.svg";
import {animated, useSprings} from 'react-spring';
import {useBehaviorSubject} from "./Utils";
import ReactGA from "react-ga4";
import {useQuery} from "react-query";
import {FirebaseClient} from "./FirebaseClient";

export function SpeechOverlay() {
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const speaking = useBehaviorSubject(SpeechClient.speaking, [])
    const buffer = useBehaviorSubject(SpeechClient.result, []);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const message = buffer.join("")
    const tooLong = message.length > (config?.wordLimit ?? 140)
    const canSend = !!message && !tooLong

    useEffect(resetTranscriptPosition, [buffer]);

    function resetTranscriptPosition() {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    }

    if (!speaking) return <Fragment/>

    return <div id='chat-speech'>
        <button className='exit' onClick={SpeechClient.cancel}>
            <img src={speechExit} alt='exit'/>
        </button>
        <div>
            <h2>マイクにむかって<br/>はなそう</h2>
            <Animation/>
            <div className='transcripts font-bold font-large'>
                {buffer.map((b, i) => <p key={i}>{b}</p>)}
                <div ref={messagesEndRef}/>
            </div>
            <button className='large-button blue' disabled={!canSend} onClick={onSubmitButtonPressed}>そうしん</button>
            {tooLong && <p style={{color: 'red'}}>テキストが長すぎます ({message.length}/{config.wordLimit})</p>}
        </div>
    </div>
}

function onSubmitButtonPressed() {
    SpeechClient.send()

    ReactGA.event({
        category: 'Chat',
        action: 'VoiceInputSubmission',
        label: 'tap',
    })
}

function Animation() {
    const height = 60
    const [springs, setSprings] = useSprings(7, () => ({
        height: height,
        config: {tension: 170, friction: 26}
    }));

    useEffect(() => {
        const interval = setInterval(() => setSprings(i => ({height: makeHeight(i)})), 16)
        return () => clearInterval(interval);
    }, [setSprings])

    function makeHeight(index: number) {
        const time = Date.now()
        const n = Math.sin(time * 0.005 + index * 0.5) * 0.5 + 0.7
        return n * height
    }

    return (
        <div className='animation'>
            {springs.map((props, i) => (
                <Bar key={i} height={props.height}/>
            ))}
        </div>
    );
}

function Bar({height}) {
    return <animated.div className='animation-bar' style={{height}}/>
}