import {Character, FirebaseClient, UserProfile} from "./FirebaseClient";
import * as rx from 'rxjs'
import TextSplitter from "./TextSplitter";
import {calculateAge} from "./Utils";
import {ErrorClient} from "./ErrorClient";
import {v4 as uuidv4} from 'uuid';
import otag from "observable-to-async-generator";
import ReactGA from "react-ga4";

type Key = number

const baseUrl: string = location.host === 'aiueo-ai.com'
    ? "https://chatgpt-pyksjysm2q-an.a.run.app/chatGpt"
    : "https://chatgpt-ezxwzqdpra-an.a.run.app/chatGpt"

export type ChatGptResponse = {
    key: Key,
    text: string,
}

export namespace ChatGptClient {
    export const sentences = new rx.Subject<ChatGptResponse>()
    export const thinking = new rx.BehaviorSubject<boolean>(false)
    let character: Character
    let user: UserProfile

    export function init() {
        character = undefined
        user = undefined
        thinking.next(false)
    }

    export function configure(config: {
        character: Character,
        user: UserProfile
    }) {
        character = config.character
        user = config.user
    }

    export async function request(key: Key, rawContent: string) {
        console.log(`>> gpt [${key}] '${rawContent}'`)

        const [idToken, config] = await Promise.all([
            FirebaseClient.getIdToken(),
            FirebaseClient.getConfig(),
        ])

        if (!idToken) throw new Error("no token")
        console.log(idToken)

        if (rawContent.length > config.wordLimit) {
            throw new Error(`テキストが長すぎます (${rawContent.length}/${config.wordLimit})`)
        }

        thinking.next(true)

        const system = makeSystem(character.context, config.chatSystem)
        const content = makeContent(rawContent, config.chatTemplate)
        const chatMessageId = uuidv4();

        await Promise.all([
            FirebaseClient.setSystem(character.id, system),
            FirebaseClient.appendChatMessage(character.id, {
                id: chatMessageId,
                userContent: content,
                userRawContent: rawContent,
                complete: false,
            }),
        ])

        const url = `${baseUrl}?token=${idToken}&characterId=${character.id}`
        const eventSource = new EventSource(url)
        console.log('ChatGPT eventsource created')

        const splitter = new TextSplitter()
        const splits = new rx.Subject<string>()
        eventSource.onmessage = e => receive(e.data)
        eventSource.onerror = e => splits.error(e)
        splitter.onSplit = split => splits.next(split)

        function receive(line: string) {
            //console.log(line.replace(/\r?\n/g, '<br>'))

            if (line === "[DONE]") {
                splitter.flush()
                splits.complete()
                return;
            }

            try {
                const json = JSON.parse(line);
                const choice = json.choices[0]
                const token: string | undefined = choice['delta']['content']
                const finishReason: string | undefined = choice['finish_reason']
                if (finishReason === 'length') {

                    ReactGA.event({
                        category: 'Chat',
                        action: 'Error',
                        label: 'Token Length',
                    })

                    splits.error(new Error('finish_reason: length'))
                }
                if (token) {
                    splitter.append(token)
                }
            } catch (e) {
                splits.error(e)
            }
        }

        let completeSentence = ""
        try {
            for await (const split of otag(splits)) {
                console.log(`<< gpt [${key}] '${split}'`)
                if (split === "") {
                    console.warn('no content')
                    return
                }

                sentences.next({key: key, text: split})
                completeSentence += split
            }
        } catch (e) {
            console.error(e)
            ErrorClient.show({
                title: 'チャットの返信にエラーがありました',
                body: `${e}`,
            })
        } finally {
            eventSource.close()
            thinking.next(false)

            await FirebaseClient.appendChatMessage(character.id, {
                id: chatMessageId,
                assistantContent: completeSentence,
                complete: true,
            })
        }
    }

    function makeSystem(characterTemplate: string, template: string): string {
        const {name: characterName} = character
        const {gender: userGender, name: userName, birth, intro} = user
        const g = userGender === 'male' ? 'くん' : 'ちゃん'
        const age = calculateAge(birth)

        //console.log(`${template}, ${characterTemplate}, ${characterName}, ${userName}, ${g}, ${age}, ${intro}`)

        return template
            .replaceAll('$CHARACTER_SYSTEM', characterTemplate)
            .replaceAll('$USER_NAME', userName)
            .replaceAll('$USER_GENDER', g)
            .replaceAll('$USER_AGE', `${age}`)
            .replaceAll('$USER_INTRO', intro)
            .replaceAll('$CHARACTER_NAME', characterName)
    }

    function makeContent(text: string, template: string): string {
        const {name: characterName} = character
        const {gender: userGender, name: userName, birth} = user
        const g = userGender === 'male' ? 'くん' : 'ちゃん'
        const age = calculateAge(birth)
        return template
            .replaceAll('$USER_NAME', userName)
            .replaceAll('$USER_GENDER', g)
            .replaceAll('$USER_AGE', `${age}`)
            .replaceAll('$USER_TEXT', text)
            .replaceAll('$CHARACTER_NAME', characterName)
    }
}

