import * as rx from 'rxjs'

export namespace Counter {
    export const counter = new rx.BehaviorSubject<number>(0)
    let handler: number | undefined

    export function init(interval: number) {
        window.clearInterval(handler)
        handler = window.setInterval(onInterval, interval)
        counter.next(0)
    }

    function onInterval() {
        counter.next(counter.value + 1)
    }
}