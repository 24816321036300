import HomeHeader from "./HomeHeader";
import {Link} from "react-router-dom";

export default function PurchaseCancelPage() {
    return <div>
        <HomeHeader/>
        <div id='purchase-result'>
            <h2>決済が失敗しました。</h2>
            <p>時間をおいて再度お試しください。</p>
            <div>
                <Link to='/purchase' className='large-button blue'>再び購入する</Link>
                <Link to='/profile' className='large-button'>マイページへ</Link>
            </div>
        </div>
    </div>
}
