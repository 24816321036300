export namespace AudioClient {
    let audioElement: HTMLAudioElement | undefined

    export function initAudioContext() {
        audioElement?.remove()
        audioElement = window.document.createElement("audio")
        window.document.body.appendChild(audioElement)
    }

    export async function play(arrayBuffer: ArrayBuffer) {
        const blob = new Blob([arrayBuffer], {type: 'audio/wav'})
        audioElement.src = URL.createObjectURL(blob)
        try {
            await Promise.all([
                audioElement.play(),
                completePlayback(audioElement),
            ])
        } finally {
            URL.revokeObjectURL(audioElement.src)
        }
        console.log(`playback done`)
    }

    function completePlayback(audio: HTMLAudioElement) {
        return new Promise<void>((resolve, reject) => {
            audioElement.onended = () => resolve()
            audioElement.onerror = (e) => reject(e)
        })
    }
}