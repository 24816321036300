import HomeHeader from "./HomeHeader";
import React, {useState} from "react";
import {useBehaviorSubject} from "./Utils";
import {FirebaseClient} from "./FirebaseClient";
import {useNavigate} from "react-router-dom";
import {ErrorClient} from "./ErrorClient";
import ReactGA from 'react-ga4';

export default function ProfileEditPage() {
    return <div>
        <HomeHeader/>
        <Main/>
    </div>
}

function Main() {
    const profile = useBehaviorSubject(FirebaseClient.users);
    const [name, setName] = useState(profile?.name ?? "");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const canSubmit = !loading && name !== "" && name !== profile.name

    return <div id='profile-edit' className='vertical-form'>
        <h2>なまえをへんこう</h2>
        <form>
            <div className='item split'>
                <p className='font-bold'>おなまえ</p>
                <input type="text" defaultValue={profile?.name ?? ""} onChange={v => setName(v.target.value)}/>
            </div>
            <button type='button' className='large-button blue' disabled={!canSubmit} onClick={onSubmit}>ほぞん</button>
            <button type='button' className='large-button' onClick={cancel}>やめる</button>
        </form>
    </div>

    async function onSubmit() {
        setLoading(true)
        try {
            await FirebaseClient.updateUserProfile({name: name})
            ReactGA.event({
                category: 'MyPage',
                action: "EditName",
                label: "tap",
            })
        } catch (e) {
            console.error(e)
            ErrorClient.show({
                title: '送信に失敗しました',
                body: `${e}`,
            })
        } finally {
            navigate('/profile')
        }
    }

    function cancel() {
        navigate('/profile')
    }
}