import React, {FormEvent, useState} from 'react';
import {FirebaseClient} from './FirebaseClient'
import characters from './res/characters.png'
import mail from './res/mail.svg'
import google from './res/google.svg'
import SignInHeader from "./SignInHeader";

export default function SignInPage() {
    return <div>
        <SignInHeader/>
        <Internal/>
    </div>
}

function Internal() {
    const [signingUp, setSigningUp] = useState(false)
    const [linkSent, setLinkSent] = useState(false);

    if (linkSent) {
        return <LinkSentMessage/>
    }

    if (signingUp) {
        return <SignUpForm/>
    }

    return <SignInForm/>

    function SignInForm() {
        const [loading, setLoading] = useState(false)
        const [error, setError] = useState(null as string | null);

        return <div id='sign-in-options'>
            <div style={{textAlign: "center", margin: '20px'}}>
                <img src={characters} alt='characters'/>
                <h2>キャラクターと<br/>おはなししよう！</h2>
            </div>
            <ul>
                <li><Button icon={mail} text='メールで登録/ログイン' fn={() => setSigningUp(true)}/></li>
                <li><Button icon={google} text='Googleで登録/ログイン' fn={onGoogleButtonPressed}/></li>
            </ul>
            {error && <div>{error}</div>}
        </div>

        function Button(props: { icon: string, text: string, fn: () => void }) {
            const {fn, icon, text} = props;
            return <button onClick={fn} disabled={loading}>
                <img src={icon} alt={text}/>
                <p>{text}</p>
            </button>
        }

        async function onGoogleButtonPressed() {
            try {
                setLoading(true)
                await FirebaseClient.signInWithGoogle()
            } catch (e) {
                console.log(e)
                setError(`${e}`)
            } finally {
                setLoading(false)
            }
        }
    }

    function SignUpForm() {
        return <div id='sign-up'>
            <h2>メールで登録/ログイン</h2>
            <form onSubmit={handleEmailPasswordSubmit}>
                <p className='font-bold font-small'>メールアドレス</p>
                <input type="email" name="email" placeholder="example@aiueo.com" className='email'/>
                <div/>
                <input type="submit" value="ログインURLを送信" className='large-button blue'/>
            </form>
        </div>
    }

    async function handleEmailPasswordSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const {value: email} = event.target['email']
        await FirebaseClient.signUpWithEmailLink(email)
        setLinkSent(true)
    }
}

function LinkSentMessage() {
    return <div style={{margin: '20px'}}>
        <h2>メールアドレスにログイン情報を送りました。</h2>
        <p>記載されているURLからログインを行なってください。</p>
    </div>
}