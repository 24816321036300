import './index.css'
import logotype from './res/logotype.svg'
import bell from './res/bell.svg'
import profile from './res/profile.svg'
import hamburger from './res/hamburger.svg'
import {useQuery} from "react-query";
import {FirebaseClient} from "./FirebaseClient";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";
import {useBehaviorSubject} from "./Utils";

export default function HomeHeader() {
    const {data: config} = useQuery('getConfig', FirebaseClient.getConfig);
    const isDeveloper = useBehaviorSubject(FirebaseClient.isDeveloper, []);
    const announcementLink = config?.announcementLink

    return <div id="home-header" className='split'>
        <div>
            <Link to='/'>
                <img src={logotype} alt='logotype'/>
            </Link>
        </div>
        <div>
            {isDeveloper &&
                <Link to='/admin'>開発者</Link>
            }
            <button onClick={() => window.open(announcementLink, "_blank")}>
                <img src={bell} alt='notification'/>
            </button>
            <Link to='/profile'>
                <img src={profile} alt='profile'/>
            </Link>
            <Link to='/menu' onClick={onHamburgerPressed}>
                <img src={hamburger} alt='hamburger'/>
            </Link>
        </div>
    </div>

    function onHamburgerPressed() {
        ReactGA.event({
            category: 'Mypage',
            action: 'MyMenu',
            label: 'tap',
        })
    }
}
