import {FirebaseClient} from "./FirebaseClient";
import React, {Fragment, useState} from "react";
import {useBehaviorSubject} from './Utils'
import appleShare from './res/share-apple.svg'
import exit from './res/exit.svg'

export default function TouchHomePopup() {
    const user = useBehaviorSubject(FirebaseClient.users, [])
    const [done, setDone] = useState(false);
    if (done) return <Fragment/>
    if (user?.addedToHomeScreen) return <Fragment/>
    if (!isIOSSafari()) return <Fragment/>

    return <div>
        <div className='speaking-bubble touch-home-popup'>
            <div className='icon-wrapper'>
                <img src={appleShare} alt='logomark'/>
            </div>
            <p><span>ホーム画面に追加</span>でAIUEOをいつでもすぐご利用いただけます。</p>
            <img src={exit} className='cancel' alt='cancel' onClick={onClick}/>
        </div>
    </div>

    async function onClick() {
        setDone(true)
        await FirebaseClient.updateUserProfile({addedToHomeScreen: true})
    }
}

function isIOSSafari(): boolean {
    const userAgent = window.navigator.userAgent;
    const isIOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
    const isWebkit = !!userAgent.match(/WebKit/i);
    return isIOS && isWebkit && !userAgent.match(/CriOS/i);
}