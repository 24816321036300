/**
 * Copyright(c) Live2D Inc. All rights reserved.
 *
 * Use of this source code is governed by the Live2D Open Software license
 * that can be found at https://www.live2d.com/eula/live2d-open-software-license-agreement_en.html.
 */

import {CubismFramework, Option} from '../live2d/live2dcubismframework';

import * as LAppDefine from './lappdefine';
import {LAppLive2DManager} from './lapplive2dmanager';
import {LAppPal} from './lapppal';
import {LAppTextureManager} from './lapptexturemanager';
import {LAppView} from './lappview';
import {RgbaObject} from "hex-rgb";

export let canvas: HTMLCanvasElement = null;
export let s_instance: LAppDelegate = null;
export let gl: WebGLRenderingContext = null;
export let frameBuffer: WebGLFramebuffer = null;

/**
 * アプリケーションクラス。
 * Cubism SDKの管理を行う。
 */
export class LAppDelegate {
    _cubismOption: Option; // Cubism SDK Option
    _view: LAppView; // View情報
    _captured: boolean; // クリックしているか
    _mouseX: number; // マウスX座標
    _mouseY: number; // マウスY座標
    _isEnd: boolean; // APP終了しているか
    _textureManager: LAppTextureManager; // テクスチャマネージャー

    bgColor: RgbaObject = {red: 1, green: 1, blue: 1, alpha: 1}

    /**
     * クラスのインスタンス（シングルトン）を返す。
     * インスタンスが生成されていない場合は内部でインスタンスを生成する。
     *
     * @return クラスのインスタンス
     */
    public static getInstance(): LAppDelegate {
      if (s_instance == null) {
        s_instance = new LAppDelegate();
      }

      return s_instance;
    }

    /**
     * クラスのインスタンス（シングルトン）を解放する。
     */
    public static releaseInstance(): void {
      if (s_instance != null) {
        s_instance.release();
      }

      s_instance = null;
    }

    /**
     * コンストラクタ
     */
    constructor() {
        this._captured = false;
        this._mouseX = 0.0;
        this._mouseY = 0.0;
        this._isEnd = false;

        this._cubismOption = new Option();
        this._view = new LAppView();
        this._textureManager = new LAppTextureManager();
    }

    /**
     * Cubism SDKの初期化
     */
    public initializeCubism(): void {
        // setup cubism
        this._cubismOption.logFunction = LAppPal.printMessage;
        this._cubismOption.loggingLevel = LAppDefine.CubismLoggingLevel;
        CubismFramework.startUp(this._cubismOption);

        // initialize cubism
        CubismFramework.initialize();

        // load model
        LAppLive2DManager.getInstance();

        LAppPal.updateTime();

        this._view.initializeSprite();
    }

    /**
     * APPに必要な物を初期化する。
     */
    public initialize(): boolean {
      // キャンバスの作成
      canvas = document.getElementById('live2d') as HTMLCanvasElement;
      this._resizeCanvas();

      gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl') as WebGLRenderingContext
      frameBuffer = gl.getParameter(gl.FRAMEBUFFER_BINDING);

      // 透過設定
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

      // AppViewの初期化
      this._view.initialize();

      // Cubism SDKの初期化
      this.initializeCubism();

      return true;
    }

    public setVisible(visible: boolean) {
        console.log(`set visible ${visible}`)
      canvas.hidden = !visible
    }

    public loadModel(id: string) {
      this._view.loadModel(id)
    }

    /**
     * Resize canvas and re-initialize view.
     */
    public onResize(): void {
      this._resizeCanvas();
      this._view.initialize();
      this._view.initializeSprite();

      // キャンバスサイズを渡す
      const viewport: number[] = [0, 0, canvas.width, canvas.height];

      gl.viewport(viewport[0], viewport[1], viewport[2], viewport[3]);
    }

    /**
     * 解放する。
     */
    public release(): void {
      this._textureManager.release();
      this._textureManager = null;

      this._view.release();
      this._view = null;

      // リソースを解放
      LAppLive2DManager.releaseInstance();

      // Cubism SDKの解放
      CubismFramework.dispose();
    }

    /**
     * 実行処理。
     */
    public run(): void {
      // メインループ
      const loop = (): void => {
        // インスタンスの有無の確認
        if (s_instance == null) {
            return;
        }

        // 時間更新
        LAppPal.updateTime();

        // 画面の初期化
        const {red, green, blue, alpha} = this.bgColor
        gl.clearColor(red/255, green/255, blue/255, alpha);
        //console.log(this.bgColor)

        // 深度テストを有効化
        gl.enable(gl.DEPTH_TEST);

        // 近くにある物体は、遠くにある物体を覆い隠す
        gl.depthFunc(gl.LEQUAL);

        // カラーバッファや深度バッファをクリアする
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.clearDepth(1.0);

        // 透過設定
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        // 描画更新
        this._view.render();

        // ループのために再帰呼び出し
        requestAnimationFrame(loop);
      };
      loop();
    }

    /**
     * シェーダーを登録する。
     */
    public createShader(): WebGLProgram {
      // バーテックスシェーダーのコンパイル
      const vertexShaderId = gl.createShader(gl.VERTEX_SHADER);

      if (vertexShaderId == null) {
        LAppPal.printMessage('failed to create vertexShader');
        return null;
      }

      const vertexShader: string =
        'precision mediump float;' +
        'attribute vec3 position;' +
        'attribute vec2 uv;' +
        'varying vec2 vuv;' +
        'void main(void)' +
        '{' +
        '   gl_Position = vec4(position, 1.0);' +
        '   vuv = uv;' +
        '}';

      gl.shaderSource(vertexShaderId, vertexShader);
      gl.compileShader(vertexShaderId);

      // フラグメントシェーダのコンパイル
      const fragmentShaderId = gl.createShader(gl.FRAGMENT_SHADER);

      if (fragmentShaderId == null) {
        LAppPal.printMessage('failed to create fragmentShader');
        return null;
      }

      const fragmentShader: string =
        'precision mediump float;' +
        'varying vec2 vuv;' +
        'uniform sampler2D texture;' +
        'void main(void)' +
        '{' +
        '   gl_FragColor = texture2D(texture, vuv);' +
        '}';

      gl.shaderSource(fragmentShaderId, fragmentShader);
      gl.compileShader(fragmentShaderId);

      // プログラムオブジェクトの作成
      const programId = gl.createProgram();
      gl.attachShader(programId, vertexShaderId);
      gl.attachShader(programId, fragmentShaderId);

      gl.deleteShader(vertexShaderId);
      gl.deleteShader(fragmentShaderId);

      // リンク
      gl.linkProgram(programId);

      gl.useProgram(programId);

      return programId;
    }

    /**
     * View情報を取得する。
     */
    public getView(): LAppView {
      return this._view;
    }

    public getTextureManager(): LAppTextureManager {
      return this._textureManager;
    }

    /**
     * Resize the canvas to fill the screen.
     */
    private _resizeCanvas(): void {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
}
