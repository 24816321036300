import {loadStripe, Stripe} from '@stripe/stripe-js';
import {FirebaseClient} from "./FirebaseClient";

const keyDev: string = "pk_test_51NOIrhAMa8GQeZIiYnT3Li7W7iDkHNNUi4e78fZmwZqcOySvHMO124pLova5nPwwroZqTGp3M9VcugN50jtimYbf00x1cHWMbO"
const keyProd: string = "pk_live_51NOIrhAMa8GQeZIifUVMwqgefblk9yNVeJ4cGrlMTZcxjPOHCZJzWJ7OnpM3xBbsovwl7QeUJ7JMDdIBgvAFjKPB004dqBnwPl"
const key = location.host === 'aiueo-ai.com' ? keyProd : keyDev

export namespace StripeClient {
    let stripe: Stripe | undefined

    export async function checkout(product: string, priceId: string) {
        if (!stripe) {
            stripe = await loadStripe(key)
            console.log('stripe initialized')
        }

        const successUrl = makeUrlWithPath("/purchase/success")
        const cancelUrl = makeUrlWithPath("/purchase/cancel")
        const sessionId = await FirebaseClient.checkout(product, priceId, successUrl, cancelUrl)
        return stripe.redirectToCheckout({sessionId: sessionId})
    }
}

function makeUrlWithPath(path: string) {
    const url = new URL(window.location.href)
    url.pathname = path
    return url.href
}